import { getCookie, setCookie, deleteCookie } from "cookies-next";
import { CookieValueTypes } from "cookies-next/lib/types";
import { IncomingMessage, ServerResponse } from "http";
import { NextApiRequestCookies } from "next/dist/server/api-utils";

export const setAccessToken = (
    accessToken: string | null,
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): void => {
    if (accessToken) {
        const cookieHoursLifeTime = process.env
            .ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS
            ? parseInt(process.env.ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS, 10)
            : 1;

        setCookie(`_tf_token`, accessToken, {
            req,
            res,
            maxAge: 60 * 60 * cookieHoursLifeTime,
        });
    }
};
export const setOfferId = (
    oid: string | null,
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): void => {
    if (oid) {
        const cookieHoursLifeTime = process.env
            .ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS
            ? parseInt(process.env.ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS, 10)
            : 1;

        setCookie(`_tf_oid`, oid, {
            req,
            res,
            maxAge: 60 * 60 * cookieHoursLifeTime,
        });
    }
};
export const getOfferId = (
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): CookieValueTypes => {
    const _tf_oid = getCookie("_tf_oid", { req, res });
    return _tf_oid;
};

export const setLocaleCookie = (
    locale: string,
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): void => {
    setCookie(`_tf_locale`, locale, {
        req,
        res,
    });
};

export const getLocale = (
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): CookieValueTypes => {
    const locale = getCookie("_tf_locale", { req, res });
    return locale;
};

export const setVisitorId = (
    visitorId: string | null,
    req:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res: ServerResponse,
): void => {
    if (visitorId) {
        const cookieHoursLifeTime = process.env
            .ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS
            ? parseInt(process.env.ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS, 10)
            : 1;

        setCookie(`_tf_visitorId`, visitorId, {
            req,
            res,
            maxAge: 60 * 60 * cookieHoursLifeTime,
        });
    }
};

export const setVisitId = (
    visitId: string | null,
    req:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res: ServerResponse,
): void => {
    if (visitId) {
        const cookieHoursLifeTime = process.env
            .ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS
            ? parseInt(process.env.ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS, 10)
            : 1;

        setCookie(`_tf_visitId`, visitId, {
            req,
            res,
            maxAge: 60 * 60 * cookieHoursLifeTime,
        });
    }
};

export const setAffiliateListIdCookie = (
    affiliateListId: string | number | null,
    req:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res: ServerResponse | undefined,
): void => {
    if (affiliateListId) {
        setCookie(`_affiliateListReqId`, affiliateListId, {
            req,
            res,
        });
    } else {
        deleteCookie(`_affiliateListReqId`, { req, res });
    }
};

export const setListIdCookie = (
    listId: string | number | null,
    req:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res: ServerResponse | undefined,
): void => {
    if (listId) {
        setCookie(`_listReqId`, listId, {
            req,
            res,
        });
    } else {
        deleteCookie(`_listReqId`, { req, res });
    }
};
export const setTimeOnSiteCookie = (
    tos: number,
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse | undefined,
): void => {
    const cookieHoursLifeTime = process.env
        .ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS
        ? parseInt(process.env.ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS, 10)
        : 1;
    setCookie(`_TOS`, tos, {
        req,
        res,
        maxAge: 60 * 60 * cookieHoursLifeTime,
    });
};

export const getAccessToken = (
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): CookieValueTypes => {
    const accessToken = getCookie("_tf_token", { req, res });
    return accessToken;
};

export const getTimeOnSite = (
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): CookieValueTypes => {
    const tos = getCookie("_TOS", { req, res });
    return tos;
};

export const setLanderCookie = (
    value: string,
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): void => {
    const cookieHoursLifeTime = process.env.FB_LANDER_EXPIRATION_TIME_IN_HOURS
        ? parseInt(process.env.FB_LANDER_EXPIRATION_TIME_IN_HOURS, 10)
        : 1;
    setCookie("_fb_lander", value, {
        req,
        res,
        maxAge: 60 * 60 * cookieHoursLifeTime * 10,
    });
};

export const getLanderCookie = (
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): CookieValueTypes => {
    const lander = getCookie("_fb_lander", { req, res });
    return lander;
};

export const getVisitorId = (
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): CookieValueTypes => {
    const visitorId = getCookie("_tf_visitorId", { req, res });
    return visitorId;
};

export const getVisitId = (
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): CookieValueTypes => {
    const visitId = getCookie("_tf_visitId", { req, res });
    return visitId;
};

export const getListId = (
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): CookieValueTypes => {
    const listId = getCookie("_listReqId", { req, res });
    return listId;
};

export const getAffiliateListId = (
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): CookieValueTypes => {
    const affiliateListId = getCookie("_affiliateListReqId", { req, res });
    return affiliateListId;
};

export const setTestVariantId = (
    testType: "form" | "style",
    variantId: string | null,
    categorySlug: string | null,
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): void => {
    if (variantId) {
        const key = `_tf_${testType}_${categorySlug ?? ""}_variant_id`;

        const cookieHoursLifeTime = process.env
            .ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS
            ? parseInt(process.env.ACCESS_TOKEN_EXPIRATION_TIME_IN_HOURS, 10)
            : 1;

        setCookie(key, variantId, {
            req,
            res,
            maxAge: 60 * 60 * cookieHoursLifeTime,
        });
    }
};

export const getTestVariantId = (
    testType: "form" | "style",
    categorySlug: string | null,
    req?:
        | (IncomingMessage & {
              cookies: NextApiRequestCookies;
          })
        | undefined,
    res?: ServerResponse,
): CookieValueTypes => {
    const key = `_tf_${testType}_${categorySlug ?? ""}_variant_id`;

    const variantId = getCookie(key, { req, res });
    return variantId;
};
