import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialForm {
    active: boolean;
}

const initialState: InitialForm = {
    active: false,
};

const vpnpricecomparisonModal = createSlice({
    name: "vpnpricecomparisonModal",
    initialState,
    reducers: {
        setModalActive: (state, action: PayloadAction<boolean>) => {
            state.active = action.payload;
        },
    },
});

export const { setModalActive } = vpnpricecomparisonModal.actions;

export default vpnpricecomparisonModal.reducer;
