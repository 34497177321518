import React from "react";

const LeadId: React.FC = () => {
    return (
        <script
            id="LeadIdScript"
            defer
            dangerouslySetInnerHTML={{
                __html: `(function() {
                    var s = document.createElement('script');
                    s.id = 'LeadIdScript_campaign';
                    s.type = 'text/javascript';
                    s.async = true;
                    s.src = '//create.lidstatic.com/campaign/235df7d8-ca30-cbd7-1eba-1cdf9ba08706.js?snippet_version=2';
                    var LeadIdScript = document.getElementById('LeadIdScript');
                    LeadIdScript.parentNode.insertBefore(s, LeadIdScript);
                    })();`,
            }}
        ></script>
    );
};

export default LeadId;
