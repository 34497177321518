import { combineReducers } from "redux";
import warrantyBoxForm from "./slices/warrantyBoxForm";
import popupForm from "./slices/popupForm";
import fields from "./slices/fields";
import session from "./slices/session";
import modal from "./slices/modal";

const perfectAutoWarranty = combineReducers({
    warrantyBoxForm,
    popupForm,
    fields,
    session,
    modal,
});

export default perfectAutoWarranty;
