import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialForm {
    active: boolean;
    content:
        | undefined
        | "leadForm"
        | "terms"
        | "privacy"
        | "listingPage"
        | "categoryPage"
        | "disclaimer"
        | "disclaimer2"
        | "homePage"
        | "ccpaPrivacyNotice"
        | "sideWidget";
}

const initialState: InitialForm = {
    active: false,
    content: undefined,
};

const sharedModalSlice = createSlice({
    name: "sharedModal",
    initialState,
    reducers: {
        setModal: (
            state,
            action: PayloadAction<{
                active: boolean;
                content:
                    | undefined
                    | "leadForm"
                    | "terms"
                    | "privacy"
                    | "listingPage"
                    | "categoryPage"
                    | "homePage"
                    | "disclaimer"
                    | "disclaimer2"
                    | "ccpaPrivacyNotice"
                    | "sideWidget";
            }>,
        ) => {
            state.active = action.payload.active;
            state.content = action.payload.content;
        },
    },
});

export const { setModal } = sharedModalSlice.actions;

export default sharedModalSlice.reducer;
