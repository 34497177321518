import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialForm {
    currentStep: number;
    submitted: boolean;
}

const initialState: InitialForm = {
    currentStep: 1,
    submitted: false,
};

const popupFormSlice = createSlice({
    name: "popupForm",
    initialState,
    reducers: {
        changePopupFormCurrentStep: (state, action: PayloadAction<number>) => {
            state.currentStep = action.payload;
        },

        setPopupFormSubmitted: (state, action: PayloadAction<boolean>) => {
            state.submitted = action.payload;
        },
    },
});

export const { changePopupFormCurrentStep, setPopupFormSubmitted } =
    popupFormSlice.actions;

export default popupFormSlice.reducer;
