import React, { ReactElement, useEffect } from "react";
import { useDomainContext } from "@hooks/useDomainContext";

export default function OutBrain(): ReactElement {
    const { visitDetails } = useDomainContext();

    useEffect(() => {
        if (visitDetails?.trafficSourceNetwork?.toLowerCase() === "outbrain") {
            window.setTimeout(() => {
                window.scrollTo({
                    top: 22,
                    behavior: "smooth",
                });
            }, 100);
        }
    }, []);

    if (visitDetails?.trafficSourceNetwork?.toLowerCase() === "outbrain") {
        return (
            <div
                className="text-center  text-xs py-1 relative"
                style={{
                    lineHeight: "1.1",
                    zIndex: "1",
                    backgroundColor: "#fff",
                }}
            >
                <div className="max-w-7xl mx-auto text-right pr-6">
                    Advertorial
                </div>
            </div>
        );
    }
    return <></>;
}
