import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialForm {
    currentStep: number;
    numberOfSteps: number;
    submitted: boolean;
}

export const initialState: InitialForm = {
    currentStep: 1,
    numberOfSteps: 8,
    submitted: false,
};

const warrantyBoxFormSlice = createSlice({
    name: "warrantyBoxForm",
    initialState,
    reducers: {
        changeWarrantyBoxFormCurrentStep: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.currentStep = action.payload;
        },

        setFormSubmitted: (state, action: PayloadAction<boolean>) => {
            state.submitted = action.payload;
        },
    },
});

export const { changeWarrantyBoxFormCurrentStep, setFormSubmitted } =
    warrantyBoxFormSlice.actions;

export default warrantyBoxFormSlice.reducer;
