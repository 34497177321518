import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useState,
} from "react";

interface FormContextInterface {
    formSubmitted: boolean;
    setFormSubmitted: (val: boolean) => void;
    setPopupFormSubmitted: { function: (() => void) | null };
    setSetPopupFormSubmittedFunction: (val: { function: () => void }) => void;
    skipToThankYou: boolean;
    setSkipToThankYou: (val: boolean) => void;
    goToThankYouPage: boolean;
    setGoToThankYouPage: (val: boolean) => void;
    isSecondServiceSubmitted: boolean;
    setSecondServiceSubmitted: (val: boolean) => void;
}

const FormContext = createContext<FormContextInterface>(undefined!);

export const FormProvider = ({
    children,
}: {
    children: ReactNode;
}): ReactElement => {
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [skipToThankYou, setSkipToThankYou] = useState(false);
    const [goToThankYouPage, setGoToThankYouPage] = useState(false);
    const [isSecondServiceSubmitted, setSecondServiceSubmitted] =
        useState(false);
    const [setPopupFormSubmitted, setSetPopupFormSubmittedFunction] = useState<{
        function: (() => void) | null;
    }>({ function: null });

    return (
        <FormContext.Provider
            value={{
                formSubmitted,
                setFormSubmitted,
                setPopupFormSubmitted,
                setSetPopupFormSubmittedFunction,
                skipToThankYou,
                setSkipToThankYou,
                isSecondServiceSubmitted,
                setSecondServiceSubmitted,
                goToThankYouPage,
                setGoToThankYouPage,
            }}
        >
            {children}
        </FormContext.Provider>
    );
};

export const useFormContext = () => useContext(FormContext);
