/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import axios, { AxiosError, AxiosPromise } from "axios";
import { captureException } from "@sentry/nextjs";
import { getAccessToken } from "@lib/shared/cookies";

const HTTP = {
    server: axios.create({
        baseURL: process.env.BACKEND_URL,
        headers: {
            "Content-Type": "application/json",
            accept: "application/json",
        },
    }),
    client: axios.create({
        headers: {
            "Content-Type": "application/json",
            accept: "application/json",
        },
    }),
};

HTTP.server.interceptors.request.use(
    (config) => {
        config.baseURL = process.env.BACKEND_URL;

        return config;
    },
    (error) => Promise.reject(error),
);

HTTP.client.interceptors.request.use(
    (config) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        config.headers["X-DOMAIN-NAME"] = window.location.host;
        config.baseURL = window.location.origin;

        const controller = new AbortController();

        const accessToken = getAccessToken();
        if (!accessToken || accessToken === "undefined") {
            controller.abort();
            window.location.reload();
        }

        return {
            ...config,
            signal: controller.signal,
        };
    },
    (error) => Promise.reject(error),
);
const captureError = (error: AxiosError, errorMessage = "") => {
    const errorObj: { [key: string]: any } = {
        frontendUrl:
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            error.response?.config.headers["X-DOMAIN-NAME"],
        backendUrl: error.response?.config.url,
        headers: error.response?.config.headers,
        data: error.response?.config.data,
        response: error.response?.data,
        status: error.response?.status,
    };
    if (errorMessage) {
        errorObj["errorMessage"] = errorMessage;
    }

    if (errorMessage.indexOf("Inspector") > -1) {
        errorObj["inspector"] = true;
        errorObj["status"] = 577;
    }
    captureException(error, {
        tags: errorObj,
        extra: errorObj,
    });
};
HTTP.server.interceptors.response.use(
    (response) => response,
    (error: AxiosError) => {
        if (error.config?.url?.includes("inspector")) {
            return Promise.reject(error);
        }

        if (
            error.response?.status === 404 &&
            (error?.config?.url?.indexOf("forms") ?? -1) > -1
        ) {
            const urlArray = error?.config?.url?.split("/") ?? "";
            captureError(
                error,
                `FORM NOT FOUND (${urlArray[urlArray.length - 1]})`,
            );
        }

        if (
            error?.response?.status !== 403 &&
            error?.response?.status !== 404 &&
            error?.response?.status !== 422
        ) {
            captureError(error);
        }

        return Promise.reject(error);
    },
);

export const apiHandler = async <T>(
    promise: () => AxiosPromise<any>,
): Promise<
    { data: T | null; error: AxiosError } | { data: T; error: null }
> => {
    try {
        const response = await promise();
        return { data: response.data as T, error: null };
    } catch (error) {
        return {
            data: (error as AxiosError<T>).response?.data ?? null,
            error: error as AxiosError,
        };
    }
};
export default HTTP;
