import { ReactElement } from "react";
import Script from "next/script";

export default function ClickyScript({ id }: { id: string }): ReactElement {
    return (
        <>
            <Script
                id="click-script"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `   
                (function() {
                    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
                    g.async=true; g.id='click-script-id'; g.src='//static.getclicky.com/${id}.js'; s.parentNode.insertBefore(g,s);
                })();
            `,
                }}
            />
            <noscript
                dangerouslySetInnerHTML={{
                    __html: `<p><img alt="Clicky" width="1" height="1" src="//in.getclicky.com/${id}ns.gif" /></p>`,
                }}
            />
        </>
    );
}
