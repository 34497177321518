/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { getAccessToken } from "@lib/shared/cookies";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

export interface TrackingTimeBody {
    event: "timeOnSite" | "formTitle" | "formTitleSource";
    eventValue: string;
    eventCategory: "meta" | "conversion";
}

export interface TrackingTimeResponse {
    success: boolean;
    data: any | null;
}

export const sendTrackingTime = async (
    data: TrackingTimeBody,
): Promise<{
    data: TrackingTimeResponse["data"] | null;
    error: Error | null;
}> => {
    const accessToken = getAccessToken();

    const config: AxiosRequestConfig = {
        method: "post",
        url: `/api/trackingTime`,
        data: data,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken as string}`,
        },
    };

    const { data: Response, error } = await apiHandler<TrackingTimeResponse>(
        () => HTTP.client(config),
    );

    return {
        data: Response?.data ?? null,
        error,
    };
};
