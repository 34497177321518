import {
    createContext,
    ReactElement,
    ReactNode,
    useContext,
    useState,
    SetStateAction,
    Dispatch,
} from "react";
import { useDomainContext } from "@hooks/useDomainContext";

type CallCampaignContextProps = {
    updateShowPhoneNumber: Dispatch<SetStateAction<boolean>>;
    showPhoneNumber: boolean;
};

const CallCampaignContext = createContext<CallCampaignContextProps>({});

export const CallCampaignProvider = (props: {
    children: ReactNode;
}): ReactElement => {
    const { visitDetails } = useDomainContext();

    const [showPhoneNumber, updateShowPhoneNumber] = useState(
        visitDetails?.trafficSourceNetwork?.toLowerCase() === "bing"
            ? false
            : true,
    );
    return (
        <CallCampaignContext.Provider
            value={{
                showPhoneNumber,
                updateShowPhoneNumber,
            }}
        >
            {props.children}
        </CallCampaignContext.Provider>
    );
};

export const useCallCampaignContext = () => useContext(CallCampaignContext);
