import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialForm {
    currentStep: number;
    numberOfSteps: number;
    submitted: boolean;
    loading: boolean;
    isBuyerLoader: boolean;
}

export const initialState: InitialForm = {
    currentStep: 1,
    numberOfSteps: 8,
    submitted: false,
    loading: false,
    isBuyerLoader: false,
};

const warrantyBoxFormSlice = createSlice({
    name: "warrantyBoxForm",
    initialState,
    reducers: {
        changeWarrantyBoxFormCurrentStep: (
            state,
            action: PayloadAction<number>,
        ) => {
            state.currentStep = action.payload;
        },

        setFormSubmitted: (state, action: PayloadAction<boolean>) => {
            state.submitted = action.payload;
        },

        setIsBuyerLoader: (state, action: PayloadAction<boolean>) => {
            state.isBuyerLoader = action.payload;
        },

        setFormLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        warrantyFormSuccess: (state) => {
            state.loading = false;
            state.submitted = true;
            state.currentStep = state.numberOfSteps;
        },
    },
});

export const {
    changeWarrantyBoxFormCurrentStep,
    setFormSubmitted,
    setFormLoading,
    warrantyFormSuccess,
    setIsBuyerLoader,
} = warrantyBoxFormSlice.actions;

export default warrantyBoxFormSlice.reducer;
