import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialForm {
    currentStep: number;
    success: boolean;
}

const initialState: InitialForm = {
    currentStep: 1,
    success: false,
};

const popupFormSlice = createSlice({
    name: "popupForm",
    initialState,
    reducers: {
        changePopupFormCurrentStep: (state, action: PayloadAction<number>) => {
            state.currentStep = action.payload;
        },

        setPopupFormSuccess: (state, action: PayloadAction<boolean>) => {
            state.success = action.payload;
        },
    },
});

export const { changePopupFormCurrentStep, setPopupFormSuccess } =
    popupFormSlice.actions;

export default popupFormSlice.reducer;
