import { useRef } from "react";

export const useTimeOnSite = (
    initialTimeOnSite: string,
    disableTimeOnSite: boolean = false,
) => {
    const isActive = useRef<boolean>(true);
    const startTime = useRef<number>(0);
    const totalTimeSpendOnSite = useRef<number>(
        Number(initialTimeOnSite) * 1000,
    );
    const prevTotalTimeSpendOnSite = useRef<number>(
        totalTimeSpendOnSite.current,
    );

    // function to track time
    const trackTime = () => {
        if (isActive && !disableTimeOnSite) {
            totalTimeSpendOnSite.current += Date.now() - startTime.current;
            startTime.current = Date.now();
        }
    };

    // function to start tracking time
    const startTrackingTime = () => {
        if (!disableTimeOnSite) {
            isActive.current = true;
            startTime.current = Date.now();
            trackTime();
        }
    };

    // function to stop tracking time
    const stopTrackingTime = () => {
        isActive.current = false;
        totalTimeSpendOnSite.current += Date.now() - startTime.current;
    };

    if (!disableTimeOnSite) startTrackingTime();

    return {
        trackTime,
        startTrackingTime,
        stopTrackingTime,
        totalTimeSpendOnSite,
        prevTotalTimeSpendOnSite,
    };
};
