import React, { ReactElement } from "react";

export default function FavIcons({
    externalFavicon,
}: {
    externalFavicon?: string | null;
}): ReactElement {
    return (
        <>
            {!externalFavicon ? (
                <>
                    <link
                        rel="apple-touch-icon"
                        sizes="114x114"
                        href="/assets/favicons/apple-touch-icon.png"
                        className="favicon"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="32x32"
                        href="/assets/favicons/favicon-32x32.png"
                        className="favicon"
                    />
                    <link
                        rel="icon"
                        type="image/png"
                        sizes="16x16"
                        href="/assets/favicons/favicon-16x16.png"
                        className="favicon"
                    />
                    <link
                        rel="manifest"
                        href="/assets/favicons/site.webmanifest"
                        className="favicon"
                    />
                </>
            ) : (
                <></>
            )}

            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="theme-color" content="#ffffff" />
        </>
    );
}
