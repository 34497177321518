import { FilterQuestion } from "@lib/shared/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import mapValues from "lodash/mapValues";

interface InitialForm {
    loading: boolean;
    categorySlug: string;
    listingQuestionnaireActive: boolean;
    questions: {
        [x: string]: FilterQuestion;
    };
}

const initialState: InitialForm = {
    loading: false,
    categorySlug: "auto-insurance",
    listingQuestionnaireActive: false,
    questions: {},
};

const insurancesupersaverListingSlice = createSlice({
    name: "listing",
    initialState,
    reducers: {
        setListingLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        },

        setListingQuestionnaireActive: (
            state,
            action: PayloadAction<boolean>,
        ) => {
            state.listingQuestionnaireActive = action.payload;
        },

        setQuestionValue: (
            state,
            action: PayloadAction<{ name: string; value: string }>,
        ) => {
            state.questions[action.payload.name].value = action.payload.value;
            const filters = { ...state.questions };
            filters[action.payload.name].value = action.payload.value;

            localStorage.setItem(
                "filters",
                JSON.stringify({
                    ...JSON.parse(localStorage.getItem("filters") || "{}"),
                    ...mapValues(filters, (filter) => filter.value),
                }),
            );
        },

        initiateQuestionnaireQuestions: (
            state,
            action: PayloadAction<{
                [x: string]: FilterQuestion;
            }>,
        ) => {
            state.questions = action.payload;
        },
    },
});

export const {
    setListingLoading,
    setListingQuestionnaireActive,
    setQuestionValue,
    initiateQuestionnaireQuestions,
} = insurancesupersaverListingSlice.actions;

export default insurancesupersaverListingSlice.reducer;
