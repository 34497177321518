import { combineReducers } from "redux";

import perfectAutoWarrantyReducer from "./perfectautowarranty/reducers";
import vpnpricecomparisonReducer from "./vpnpricecomparison/reducers";
import thecarwarrantyReducer from "./thecarwarranty/reducers";
import insurancesupersaverReducer from "./insurancesupersaver/reducers";
import sharedReducer from "./shared/reducers";

import { store } from "./store";

const rootReducer = combineReducers({
    perfectAutoWarranty: perfectAutoWarrantyReducer,
    vpnpricecomparison: vpnpricecomparisonReducer,
    thecarwarranty: thecarwarrantyReducer,
    insurancesupersaver: insurancesupersaverReducer,
    shared: sharedReducer,
});

export type RootState = ReturnType<typeof store.getState>;

export default rootReducer;
