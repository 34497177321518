import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialForm {
    active: boolean;
    content: undefined | "leadForm" | "terms" | "privacy";
}

const initialState: InitialForm = {
    active: false,
    content: undefined,
};

const perfectautowarrantyModalSlice = createSlice({
    name: "perfectautowarrantyModal",
    initialState,
    reducers: {
        setModal: (
            state,
            action: PayloadAction<{
                active: boolean;
                content: undefined | "leadForm" | "terms" | "privacy";
            }>,
        ) => {
            state.active = action.payload.active;
            state.content = action.payload.content;
        },
    },
});

export const { setModal } = perfectautowarrantyModalSlice.actions;

export default perfectautowarrantyModalSlice.reducer;
