import { FormFields } from "@redux/perfectautowarranty/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialForm {
    manufacturingYear: {
        value: string;
        valid: boolean;
        errorMessage: string;
    };
    vehicleMake: {
        value: string;
        valid: boolean;
        errorMessage: string;
    };
    vehicleModel: {
        value: string;
        valid: boolean;
        errorMessage: string;
    };

    vehicleMileage: {
        value: string;
        valid: boolean;
        errorMessage: string;
    };
    firstName: {
        value: string;
        valid: boolean;
        errorMessage: string;
    };
    lastName: {
        value: string;
        valid: boolean;
        errorMessage: string;
    };
    email: {
        value: string;
        valid: boolean;
        errorMessage: string;
    };
    phoneNumber: {
        value: string;
        valid: boolean;
        errorMessage: string;
    };
    zipCode: {
        value: string;
        valid: boolean;
        errorMessage: string;
    };
}

export const initialState: InitialForm = {
    manufacturingYear: {
        value: "2019",
        valid: true,
        errorMessage: "",
    },
    vehicleMake: {
        value: "",
        valid: true,
        errorMessage: "",
    },
    vehicleModel: {
        value: "",
        valid: true,
        errorMessage: "",
    },
    vehicleMileage: { value: "", valid: true, errorMessage: "" },
    firstName: {
        value: "",
        valid: true,
        errorMessage: "",
    },
    lastName: {
        value: "",
        valid: true,
        errorMessage: "",
    },
    email: {
        value: "",
        valid: true,
        errorMessage: "",
    },
    phoneNumber: {
        value: "",
        valid: true,
        errorMessage: "",
    },
    zipCode: {
        value: "",
        valid: true,
        errorMessage: "",
    },
};

const warrantyBoxFormSlice = createSlice({
    name: "formFields",
    initialState,
    reducers: {
        hydratePerfectAutoWarrantyFields: (
            state,
            action: PayloadAction<InitialForm>,
        ) => {
            return action.payload;
        },

        changeWarrantyFormValue: (
            state,
            action: PayloadAction<{
                fieldName: FormFields;
                fieldValue: string;
            }>,
        ) => {
            state[action.payload.fieldName].value = action.payload.fieldValue;

            if (action.payload.fieldName === "vehicleMake") {
                state["vehicleModel"].value = "";
            }
        },

        changeWarrantyFormValidation: (
            state,
            action: PayloadAction<{
                fieldName: FormFields;
                state: { valid: boolean; errorMessage: string };
            }>,
        ) => {
            state[action.payload.fieldName].valid = action.payload.state.valid;
            state[action.payload.fieldName].errorMessage =
                action.payload.state.errorMessage;
        },
    },
});

export const {
    changeWarrantyFormValue,
    changeWarrantyFormValidation,
    hydratePerfectAutoWarrantyFields,
} = warrantyBoxFormSlice.actions;

export default warrantyBoxFormSlice.reducer;
