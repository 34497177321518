import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialForm {
    leadId: number | null;
}

const initialState: InitialForm = {
    leadId: null,
};

const userSessionSlice = createSlice({
    name: "warrantyBoxForm",
    initialState,
    reducers: {
        setLeadId: (state, action: PayloadAction<number | null>) => {
            state.leadId = action.payload;
        },
    },
});

export const { setLeadId } = userSessionSlice.actions;

export default userSessionSlice.reducer;
