import { useEffect } from "react";
import { useFormContext } from "./useFormContext";
import { useDomainContext } from "./useDomainContext";
import OneSignal from "react-onesignal";
import { pushTracking } from "src/api/pushTracking";
import { useRouter } from "next/router";
import { PushOptions } from "src/api/domainInfo";
import { Category } from "@lib/shared/types";

const initOneSignal = (
    pushAppId: string,
    leadId: string,
    category: Category,
    options?: PushOptions,
) => {
    OneSignal.init({
        appId: pushAppId,
        allowLocalhostAsSecureOrigin: true,
        promptOptions: options?.promptOptions,
        notifyButton: options?.notifyButton,
    })
        .then(() => {
            OneSignal.User.PushSubscription.addEventListener(
                "change",
                (res) => {
                    if (res.current.id) {
                        void pushTracking({
                            leadId,
                            subscriberId: res.current.id,
                            categoryId: category.id,
                        });
                        OneSignal.User.addAliases({
                            leadId,
                            external_id: leadId,
                        });
                        OneSignal.User.addTags({
                            categoryId: String(category.id),
                            categoryName: String(category.name),
                        });
                    }
                },
            );
            void OneSignal.Slidedown.promptPush();
        })
        .catch((e) => {
            // eslint-disable-next-line no-console
            console.log("~ one signal error:", e);
        });
};

export default function useOneSignal() {
    const { formSubmitted, goToThankYouPage } = useFormContext();
    const { category, domain } = useDomainContext();
    const pushAppId = domain?.notificationsSettings?.pushAppId;
    const { asPath, query } = useRouter();

    useEffect(() => {
        const leadId = localStorage.getItem("mainId");

        if (formSubmitted && pushAppId && leadId && !goToThankYouPage) {
            initOneSignal(
                pushAppId,
                leadId,
                category!,
                domain.notificationsSettings?.pushOptions,
            );
        }
    }, [formSubmitted]);

    useEffect(() => {
        if (
            asPath.includes("/thank-you") &&
            query.data &&
            query.zc &&
            category
        ) {
            const leadId = localStorage.getItem("mainId");
            if (pushAppId && leadId) {
                initOneSignal(
                    pushAppId,
                    leadId,
                    category,
                    domain.notificationsSettings?.pushOptions,
                );
            }
        }
    }, []);
}
