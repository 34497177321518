import { Category } from "@lib/shared/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialForm {
    insuranceCategory: Category | null;
}

const initialState: InitialForm = {
    insuranceCategory: {
        name: "Auto Insurance",
        slug: "auto-insurance",
        slugAlias: "auto",
    },
};

const insurancesupersaverSellingBoxSlice = createSlice({
    name: "insurancesupersaverSellingBox",
    initialState,
    reducers: {
        setInsuranceCategory: (
            state,
            action: PayloadAction<Category | null>,
        ) => {
            state.insuranceCategory = action.payload;
        },
    },
});

export const { setInsuranceCategory } =
    insurancesupersaverSellingBoxSlice.actions;

export default insurancesupersaverSellingBoxSlice.reducer;
