import { combineReducers } from "redux";
import session from "./slices/session";
import modal from "./slices/modal";
import listing from "./slices/listing";

const shared = combineReducers({
    session,
    modal,
    listing,
});

export default shared;
