import React from "react";

const MinFraudScript: React.FC = () => {
    const enableMinFraudScript =
        process.env.ENABLE_MAXMIND_MINFRAUD?.toLowerCase() === "true";
    const maxMindAccountId = process.env.MAXMIND_ACCOUNT_ID;

    return enableMinFraudScript && maxMindAccountId ? (
        <script
            id="maxmindMinfraudScript"
            defer
            dangerouslySetInnerHTML={{
                __html: `(function() {
                    var mmapiws = window.__mmapiws = window.__mmapiws || {};
                    mmapiws.accountId = ${maxMindAccountId};
                    var loadDeviceJs = function() {
                      var element = document.createElement('script');
                      element.async = true;
                      element.src = 'https://device.maxmind.com/js/device.js';
                      document.body.appendChild(element);
                    };
                    if (window.addEventListener) {
                      window.addEventListener('load', loadDeviceJs, false);
                    } else if (window.attachEvent) {
                      window.attachEvent('onload', loadDeviceJs);
                    }
                  })();`,
            }}
        ></script>
    ) : null;
};

export default MinFraudScript;
