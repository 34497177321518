import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InitialForm {
    accessToken: string | null;
}

const initialState: InitialForm = {
    accessToken: null,
};

const userSessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        setAccessToken: (state, action: PayloadAction<string | null>) => {
            state.accessToken = action.payload;
        },
    },
});

export const { setAccessToken } = userSessionSlice.actions;

export default userSessionSlice.reducer;
