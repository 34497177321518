/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useDomainContext } from "@hooks/useDomainContext";
import { getAccessToken, getVisitId } from "@lib/shared/cookies";
import { WindowType } from "@lib/shared/types";
import Script from "next/script";
import { ReactElement, useEffect, useState } from "react";

const InspectorScript = ({
    stopInterval,
}: {
    stopInterval?: boolean | undefined;
}): ReactElement => {
    const [accessToken, setAccessToken] = useState<string | null>(null);
    const [visitId, setVisitId] = useState<string | null>(null);

    useEffect(() => {
        if (stopInterval) {
            setTimeout(() => {
                window?.clearInterval((window as WindowType).interval);
            }, 10000);
        }
    }, [stopInterval]);

    useEffect(() => {
        setAccessToken(getAccessToken() as string);
        setVisitId(getVisitId() as string);
        // @ts-ignore
        window.getAccessToken = getAccessToken;
    }, []);

    const { domain } = useDomainContext();

    return (
        <>
            <Script
                strategy="beforeInteractive"
                src="https://cdn.trafficbox.com/assets/inspector.min.js"
            />

            {accessToken && visitId && (window as any)?.inspector ? (
                <Script
                    id="inspector"
                    strategy="afterInteractive"
                    dangerouslySetInnerHTML={{
                        __html: `
                        let events = [];
                        const intervalTime = 10000;
                        inspector.record({
                            emit(event) {
                                // push event into the events array
                                events.push(event);
                            },
                        });

                        // this function will send events to the backend and reset the events array
                        function save() {
                            if (events.length == 0) return;

                            const token = window.getAccessToken();

                            if(!token) {
                                window.location.reload();
                                return;
                            };

                            const body = JSON.stringify({ events: events, visitorId: "${visitId}", domain: "${domain.name}" });
                            
                            events = [];

                            fetch("/api/inspector", {
                                method: "POST",
                                headers: {
                                    "Content-Type": "application/json",
                                    "Authorization": 'Bearer ${accessToken}',
                                },
                                body,
                            });
                        }
                        window.interval = setInterval(save, intervalTime);
                `,
                    }}
                />
            ) : null}
        </>
    );
};
export default InspectorScript;
