/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { getAccessToken } from "@lib/shared/cookies";
import HTTP, { apiHandler } from "@services/http.service";
import { AxiosRequestConfig } from "axios";

interface PushTrackingResponse {
    success: boolean;
    data: boolean;
}

export const pushTracking = async ({
    leadId,
    subscriberId,
    categoryId,
}: {
    leadId?: string;
    subscriberId: string;
    categoryId: number;
}): Promise<{
    data: PushTrackingResponse | null;
    error: Error | null;
}> => {
    const accessToken = getAccessToken();

    const config: AxiosRequestConfig = {
        method: "post",
        url: `/api/pushTracking`,
        data: {
            leadId,
            subscriberId,
            categoryId,
        },
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken as string}`,
        },
    };

    const { data: response, error } = await apiHandler<PushTrackingResponse>(
        () => HTTP.client(config),
    );

    return {
        data: response ?? null,
        error,
    };
};
